import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import EventPreview from "../components/event-preview/event-preview";
import {graphql} from "gatsby";
import { filterTermine } from "../util/filterTermine"

const TerminePage = ({data}) => {
    return (
        <Layout>
            <SEO title="Termine"/>
            <div className={'container section bottom'}>

                <h1 className={'title'}>Termine</h1>
                <h4 className={'subtitle'}>Aktuelle Termine und Veranstaltungen</h4>
                <div className="text-container">
                    { data.allMarkdownRemark.edges.filter(filterTermine).map((item, i) => {
                       return (<EventPreview key={i} node={ item.node }></EventPreview>)
                    })}
                </div>
            </div>
        </Layout>
    );
};

export default TerminePage

export const pageQuery = graphql`
    query EventsIndexQuery {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "//events//"}}
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            rawMarkdownBody
            fileAbsolutePath
            frontmatter {
              title
              time
              location
              hidden
            }
          }
        }
      }
    }
  `;
